@import url('../../../styles/customMediaQueries.css');

.topbar {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
  background-color: #fff;
  padding: 0 52px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: var(--fontFamilyHelmet);
}

.topcontainer {
  display: flex;
  justify-content: center;
  align-content: space-between;
  align-content: center;
  flex-direction: column;
  height: 100px;
}

.tiptop {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: space-between;
}

.searchbar {
  display: flex;
  flex-direction: row;
  justify-self: left;
  position: relative;
  width: 44.3%;
  height: 45px;
  @media (--viewportExtraMaxlarge) {
    width: 34.3%
  }
}

.searchicon {
  position: absolute;
  top: 14px;
  left: 15px;
  fill: black;
  width: 16px !important;
  height: 16px !important;
}
.searchForm {
  width: 100%;
  display: flex;
  align-items: center;
}

.searchbar input {
  border-radius: 50px;
  border: 1px solid #000000;
  background-color: white;
  font-family: var(--fontFamilyHelmet);
  font-size: 18px;
  font-weight: 400;
  line-height: 19.97px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
  width: 100%;
  max-width: 100%;
  padding: 10px 39px;
  height: 39px;
  @media (--viewportExtraMaxlarge) {
    padding-right: 10px;
  }
}
.searchbar input::placeholder {
  color: black;
}

.logocontainer {
  margin: 0;
  margin-top: 0;
  padding-right: 15px;
}

.logo {
  width: 15vmax;
  height: 4.5vmax;
}

.accountcart {
  display: flex;
  flex-direction: row;
  row-gap: 0.5vw;
}

.accountcart a {
  text-decoration: none;
  color: #6b7280;
  margin-right: 15px;
  gap: 90px;
  padding: 10px;
  font-family: var(--fontFamilyHelmet);
}

.accountcart a:hover {
  font-weight: 700;
}

.dropdown {
  position: relative;
  z-index: 99;
  display: inline-block;
  height: 100%;
}
.dropdown:hover .dropdownHeading {
  font-weight: 700;
}

.navSelectBox {
  position: relative;
  height: 55px;
  width: 19.4%;
  min-width: 19.4%;
  margin: 0 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--viewportExtraMaxlarge) {
    width: 25.4%;
  }
}
.navSelectBox img {
  position: relative;
}
.navSelectBox select {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 41px;
  padding-right: 24px;
  border-radius: 30px;
  height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accountsInformation {
  display: flex;
  align-items: center;
  position: relative;
  left: 8px;
}
.accountsInformation a {
  position: relative;
}
.likesIcon {
  padding: 0 27px;
  @media (--viewportExtraMaxlarge) {
    padding: 0 12px;
  }
}

.menuLinesContainer {
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  gap: 16px;
  height: 58px;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.menuLinesContainer .menuLine {
  border-top: 2px solid black;
  width: 47px;
}
.menuLinesContainerClicked {
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  gap: 16px;
  height: 58px;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.menuLinesContainerClicked .menuLine {
  border-top: 2px solid black;
  width: 47px;
}
.menuData {
  display: none;
  position: absolute;
  top: 55px;
  width: 251px;
  right: 0;
  border: 1px solid black;
  border-radius: 20px;
  padding-top: 30px;
  background-color: white;
}

@media (min-width: 1200px) {
  .menuLinesContainer:hover .menuData {
    display: block !important;
  }
}

@media (max-width: 1199px) {
  .menuLinesContainerClicked .menuData {
    display: block;
  }
}



.menuData .apparelContainer {
  border: none;
  width: 100%;
  border-top: 1px solid black;
  padding: 4px;
  text-align: left;
  position: relative;
  cursor: pointer;
}
.menuData .apparelContainer:hover {
  background-color: var(--colorLightYellow);
}
.menuData .apparelContainer:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.menuData .apparelContainer a {
  text-align: left;
  font-family: var(--fontFamilyHelmet);
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: var(--letterSpacingDescription);
}
.apparelContainer:hover .appearlDropDown {
  display: block;
}
.appearlDropDown {
  position: absolute;
  width: 616px;
  right: 0;
  top: 0;
  display: none;
}

.dropdownHeading {
  position: relative;
  top: 16%;
  transform: translateY(50%);
}

.dropdownHeading a:hover {
  font-weight: 700;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  width: 218px;
  z-index: 1;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  top: 40px;
  right: 0;
  z-index: 999;
  padding: 9px 16px;
}

/* Links inside the dropdown */
.dropdownContent a {
  display: flex;
  align-content: center;
  color: black;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.01em;
  text-align: left;
  padding-bottom: 7px;
  background: #fff;
  border-radius: 8px;
  white-space: nowrap;
}

.separator {
  border-top: 2px solid lightgrey;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.accountLinks {
  text-decoration: none;
  color: #9c9c9c;
  margin-right: 15px;
  gap: 90px;
  padding: 10px;
  font-family: var(--fontFamilyHelmet);
}
.accountMain {
  position: relative;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accountMain img {
  cursor: pointer;
}

.accountMainOpen {
  position: relative;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accountMainOpen .dropdownAccount {
  display: flex !important;
}

.navBarSelectBox {
  border: 1px solid black;
  border-radius: 30px;
  background-color: white;
  width: 100%;
  height: 39px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 16px;
  padding-right: 10px;
}
.navBarSelectBox p {
  margin: 0;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: 19.97px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  padding: 0;
  padding-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.navBarSelectedBox {
  display: none;
  position: absolute;
  top: 55px;
  width: 100%;
  border: 1px solid black;
  border-radius: 20px;
  overflow: hidden;
}
.navBarSelectedBox label {
  display: flex;
  align-items: center;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: 19.97px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  padding: 11px 17px;
  gap: 12px;
  border-top: 0.5px solid black;
  background-color: white;
  cursor: pointer;
}
.navBarSelectedBox label:first-child {
  border: none;
}
.navBarSelectedBox label:checked {
  background-color: var(--colorLightYellow);
}
.navBarSelectedBox label:hover {
  background-color: var(--colorLightYellow);
}
.navBarSelectedBox label input {
  width: auto;
}

.navBarSelectedBox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.selected {
  background-color: var(--colorLightYellow) !important;
}
.notSelected {
  background-color: white !important;
}
.navBarSelectedBox label:hover {
  background-color: var(--colorLightYellow)!important;
}
/* Create a custom radio button */
.navBarSelectedBox label .checkmark {
  position: relative;
  top: 0px;
  left: 0px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.navBarSelectedBox label:hover input ~ .checkmark {
  background-color: transparent;
  border: 1px solid black;
}

/* When the radio button is checked, add a blue background */
.navBarSelectedBox label input:checked ~ .checkmark {
  background-color: transparent;
  border: 1px solid black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.navBarSelectedBox label .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.navBarSelectedBox label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.navBarSelectedBox label .checkmark:after {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 17px;
  background-image: url('../icons/checkmark.png');
  background-size: cover;
}

.navSelectBox:hover .navBarSelectedBox {
  display: block;
}
.dropdownAccount {
  display: none;
  position: absolute;
  top: 55px;
  border: 1px solid black;
  background-color: white;
  border-radius: 20px;
  width: 184px;
  flex-direction: column;
  overflow: hidden;
}
.dropdownAccount a {
  border-top: 1px solid black;
  padding: 10px 20px;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: 19.97px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
}
.dropdownAccount a:first-child {
  border: none;
}


@media (min-width: 1200px) {
  .accountMain:hover .dropdownAccount {
    display: flex;
  }
}

@media (max-width: 1199px) {
  .accountMainOpen .menuData {
    display: flex;
  }
}

.dropdownAccount a:hover {
  background-color: var(--colorLightYellow);
}
.dropdownAccount a:active {
  background-color: var(--colorLightYellow);
}
.shoppingcart {
  height: '20px';
  width: '20px';
  color: '#d7d7d7';
}

nav ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9vw;
}

nav a {
  color: var(--colorDarkBlack);
  padding: 10px;
  font-family: var(--fontFamilyHelmet);
  font-weight: 500;
  font-size: var(--fontSizeDescription);
}

.mensApparel {
  display: flex;
  position: absolute;
  left: 51.5%;
  transform: translateX(-50%);
}

.mensApparel a:hover {
  font-weight: 700;
}

.womensApparel {
  display: flex;
  position: absolute;
  left: 20%;
  transform: translateX(-50%);
}

.womensApparel a:hover {
  font-weight: 700;
}

.homeDecor {
  display: flex;
  position: absolute;
  right: 10%;
  transform: translateX(-50%);
}

.homeDecor a:hover {
  font-weight: 700;
}

@media screen and (max-width: 960px) {


  .searchicon {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    height: 3vw;
    width: 3vw;
  }

  .searchbar input {
    border-radius: 50px;
    border: solid;
    border-color: lightgray;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5vw;
    font-weight: 'ExtraLight';
    padding-left: 40px;
  }

  .accountcart {
    display: flex;
    flex-direction: column;
    /* border: 2px solid purple; */
    column-gap: 5px;
    line-height: 1.5;
  }

  .shoppingcart {
    height: '30px';
    width: '30px';
    color: '#d7d7d7';
  }

  .accountcart a {
    text-decoration: none;
    color: #9c9c9c;
    margin-right: 15px;
    font-size: 1.4vw;
    margin: 0;
    padding: 0;
  }

  .dropdownContent a {
    display: flex;
    align-content: center;
    color: black;
    font-weight: 400;
    font-size: 1.2vw;
    padding: 5px;
    background: #fff;
    border-radius: 8px;
  }

  nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: space-between;
  }

  nav a {
    text-decoration: none;
    color: #3f3f3f;
    padding: 10px;
    font-size: 18px;
  }
}

.shoppingCart {
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 17px;
  height: 17px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: -14px;
  right: -10px;

  /* Style: red dot */
  background-color: var(--colorLightYellow);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;

  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: var(--fontSizeBase);
  font-family: var(--fontFamilyHelmet);
  padding: 0px 0px;
  padding-top: 3px;
  color: black;
}
.shopLocalBoutiquesContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 15%;
  min-height: 44px;
  margin: 0;
  background-color: var(--colorLightYellow);
  overflow: hidden;
  z-index: 1;
  margin-top: auto;
  display: block;
  @media (max-width:1023px) {
    display: none;
  }
}
.scrollAnimateContainer {
  display: flex;
  position: absolute;
  top: 10px;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
  white-space: nowrap;
  transform: scale(2);
  transition: all 1s ease;
}
.scrollAnimateContainer > div {
  display: flex;
  animation: scrollText 30s infinite linear;
  height:100%;
  align-items: center;
}

.scrollAnimateContainer h1 {
  margin: 0;
  margin-right: 40px;
  color: black;
  font-family: var(--fontFamilyHelmet) !important;
  font-size: var(--fontSizeSmall) !important;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: var(--letterSpacingDescription);
  transition: all 2s ease;
  text-align: right;
  padding-top: 1.5px;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeSmall) !important;
    padding-top: 2px;
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}
