@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}
.mobileTopBar {
  display: flex;
  height: 120px;
  align-items: end;
  @media (--viewportLarge) {
    display: none;
  }
  @media (--viewportMaxMedium) {
    height: 102px;
  }
  @media (--viewportMedium) {
    height: 114px;
  }
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--colorWhite);

  position: fixed;
  top: 0;
  z-index: 9999;

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  /* Layout */
  display: flex;
  width: 66px !important;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}
.searchMenuButton {
  padding: 0;
  border: none;
  rotate: 94deg;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.menu {
  padding: 15px 24px;
  position: relative;

  @media (--viewportMedium) {
    padding: 21px 24px;
  }
}
.searchMenu {
  padding: 12px 24px;

  @media (--viewportMedium) {
    padding: 18px 24px;
  }
}

.menuIcon,
.searchMenuIcon {
  margin: 13px 0;
  fill: black;
  stroke: black !important;
  rotate: 268deg;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 6px;
  right: 7px;

  /* Style: red dot with white border */
  background-color: var(--colorFail);
  border: 2px solid var(--colorWhite);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);

  @media (--viewportMedium) {
    top: 13px;
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;
  height: 167px;
  position: relative;
  align-items: end;

  @media (--viewportLarge) {
    display: flex;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--colorGrey700);
}
.rootSearchIcon {
  stroke: var(--colorGrey700);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 50px;
}
.neighbourHoodMobileContainer {
  position: relative;
  margin-top: 24px;
  height: 80px;
}
.neighbourHoodMobile {
  position: relative;
  display: flex;
  border: 0.5px solid black;
  color: black;
  border-radius: var(--borderRadiusButton);
  width: 100%;
  padding: 0 25px;
  margin: 0 auto;
  height: 52px;
  align-items: center;
  transition: all 0.5s ease;
}
.neighbourHoodMobile input {
  padding: 0px;
  padding-left: 14px;
  font-weight: 400;
  color: black;
  margin: 0;
  font-size: var(--fontSizeSubHeading);
  border: none;
  height: 100%;
  outline: none;
}
.selectData label {
  display: flex;
  align-items: center;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: 19.97px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  padding: 11px 17px;
  gap: 12px;
  border-top: 0.5px solid black;
  background-color: white;
  cursor: pointer;
  margin: 0;
}
.selectData label:first-child {
  border: none;
}
.selectData label:checked {
  background-color: var(--colorLightYellow);
}
.selectData label:hover {
  background-color: var(--colorLightYellow);
}
.selectData label input {
  width: auto;
}

.selectData label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.selected {
  background-color: var(--colorLightYellow) !important;
}
.notSelected {
  background-color: white !important;
}
.selectData label:hover {
  background-color: var(--colorLightYellow)!important;
}
/* Create a custom radio button */
 label .checkmark {
  position: relative;
  top: 0px;
  left: 0px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
label:hover input ~ .checkmark {
  background-color: transparent;
  border: 1px solid black;
}

/* When the radio button is checked, add a blue background */
label input:checked ~ .checkmark {
  background-color: transparent;
  border: 1px solid black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
 label .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
 label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
 label .checkmark:after {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 17px;
  background-image: url('../../../containers/LandingPage/icons/checkmark.png');
  background-size: cover;
}

.neighbourHoodMobileContainer:hover .neighbourHoodSelectBox {
  display: block;
}
.neighbourHoodSelectBox {
  position: absolute;
  top: 62px;
  border: 1px solid black;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  overflow: hidden;
  display: none;
  z-index: 1;
}
.selectData {
  margin: 0;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: 19.97px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  border-bottom: 1px solid black;
  transition: all 0.5s ease;
}
.selectData:last-child {
  border: none;
}
.mobileHelp {
  color: var(--colorGrey300);
  margin: 0;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: max(100%, 100vh);
    height: auto;
    padding: 24px;
    background-color: var(--colorWhite);
    border-bottom: none;
  }
}
.modalContainerSearchForm {
  composes: modalContainer;
  padding: 0;
  padding: var(--modalPadding);
  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
  }
}
.closeButtonIcon {
  border: none;
  min-height: auto;
  height: auto;
  background-color: transparent;
  width: fit-content !important;
}
.closeButtonIcon span {
  display: none;
}
.closeButtonIcon svg {
  width: 16px;
  height: 16px;
  fill: black;
  margin: 0;
}
.menuMobileIcon {
  display: flex;
  flex-direction: column;
  align-items: center; 
  gap: 8px;
}
.menuMobileIcon .menuLine {
  border-top: 2px solid black;
  width: 30px;
}
.accountsInformationMain {
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: 6px;
  right: 23px;
  margin-right: 8px;
}
.accountsInformationContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 20px;
  right: 23px;
}
.accountsInformationContainer span img {
  width: 29px;
  height: 29px;
}
.accountsInformationContainer a img {
  width: 20px;
  height: 20px;
}
.cartIcon {
  width: 28px !important;
  height: 24px !important;
}
.neighbourHoodMobile p {
  margin: 0;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubHeading);
  font-weight: 400;
  line-height: normal;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  padding: 0;
  padding-left: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nextButton {
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: end;
  width: 100%;
}
.nextButton img {
  width: 36px;
  rotate: 180deg;
  margin-left: auto;
}

.shopLocalBoutiquesContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 15%;
  min-height: 44px;
  margin: 0;
  background-color: var(--colorLightYellow);
  overflow: hidden;
  z-index: 1;
  margin-top: 0px;
  display: none;
  @media (max-width:1023px) {
    display: block;
  }
}
.scrollAnimateContainer {
  display: flex;
  position: absolute;
  top: 10px;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
  white-space: nowrap;
  transform: scale(2);
  transition: all 1s ease;
  @media (--viewportMaxLarge) {
   height:50%;
   top:11px;
  }
}
.scrollAnimateContainer > div {
  display: flex;
  animation: scrollText 30s infinite linear;
  height:100%;
  align-items: center;
}

.scrollAnimateContainer h1 {
  margin: 0;
  margin-right: 40px;
  color: black;
  font-family: var(--fontFamilyHelmet) !important;
  font-size: var(--fontSizeSmall) !important;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: var(--letterSpacingDescription);
  transition: all 2s ease;
  text-align: right;
  padding-top: 1.5px;

  @media (--viewportMaxLarge) {
    font-size: 10px !important;
    padding-top: 2px;
    line-height: normal;
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}
