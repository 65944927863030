.menucontainer {
  position: absolute;
  display: flex;
  top: 0px;
  z-index: 10;
  left: 106px;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  gap: 18px;
  border: 1px solid black;
}
