@import '../../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
}
.contentAccountMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
}
.contentAccountMainData {
  margin-top: 24px;
}
.contentAccessoriesContainer {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  font-family: var(--fontFamilyHelmet);
  margin-bottom: 20px;
}
.contentAccessoriesContainer a {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  font-family: var(--fontFamilyHelmet);
}
.accountLinksWrapper,
.customLinksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.accountLinksWrapper {
  margin-top: 36px;
}
.accountLinksWrapper a {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  font-family: var(--fontFamilyHelmet);
  margin-bottom: 20px;
}
.backButton {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  font-family: var(--fontFamilyHelmet);
  display: flex;
  align-items: center;
  gap: 5px;
}
.backButton img {
  width: 30px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: 0;
}

.greeting {
  /* Font */
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: var(--lineHeightBase);

  padding: 3px 0 3px 0;
  margin-bottom: 1px;
  margin-top: 16px;

  @media (--viewportMedium) {
    font-size: var(--fontSizeHeading);
    line-height: 56px;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 24px;
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  font-family: var(--fontFamilyHelmet);
  width: auto !important;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.customLinkFont {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: var(--lineHeightBase);

  padding: 4px 0 2px 0;
  /* x * 6px */
  margin-top: 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 1px 0 7px 0;
  }
}

.inbox {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 48px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationGreeting span {
  line-height: normal;
  font-size: 24px;
  @media (--viewportMedium) {
    font-size: var(--fontSizeHeading);
  }
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  font-family: var(--fontFamilyHelmet);
}
.loginLink {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  font-family: var(--fontFamilyHelmet);
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
}
.accountsInformationContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 20px;
  right: 23px;
}
.accountsInformationMain {
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: 7px;
  right: 26px;
  margin-right: 8px;
}
.accountButton {
  position: relative;
}
.accountButton .likesIconSvg {
  position: relative;
  top: -2px;
}
.accountButton .notificationBadge {
  position: absolute;
  top: -1px;
  right: 1px;
}
.accountsInformationContainer span img {
  width: 29px;
  height: 29px;
}
.accountsInformationContainer a img {
  width: 20px;
  height: 20px;
}
.searchMenu {
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.cartIcon {
  width: 28px !important;
  height: 24px !important;
}

.menuIcon,
.searchMenuIcon {
  margin: 13px 0;
  fill: black;
  stroke: black !important;
}
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  /* Layout */
  display: flex;
  width: auto;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}
.envelopeContainer {  
  position: absolute;
  left: -86px;
  margin-top: -2px;
}
.envelopeMain {
  position: relative;
}
.envelopeMain  img {
  width: 25px !important;
  height: 25px !important;
}
.envelopeMain .notificationBadge {
  height: 20px;
  border-radius: 100%;
  position: absolute;
  top: -10px;
  right: -13px;
}
.mobileNavContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}
.messageContainer {
  position: relative;
}
.messageContainer .notificationBadge {
  position: absolute;
  top: -6px;
  right: -8px;
}