.columnContainer {
    display: flex;
    flex-direction: column;
    width: 238px;
}

.categoryList {
    display: flex;
    flex-direction: column;
    font-family: var(--fontFamilyHelmet);
     font-size: var(--fontSizeSubText);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-top: 14px;
}

.categoryList .subCategory {
    position: relative !important;
}

.categoryList .subCategory .columnContainer {
    position: absolute;
    background-color: white;
    z-index: 9999;
    display: none;
    padding: 4px;
    box-shadow: none;
    border-radius: 8px;
    left: 40px;
}
.categoryList .subCategory:hover .columnContainer {
    display: block;
}


.categoryList a {
    padding: 0;
}
.categoryList div:hover { 
    font-weight: 700;
}

.categoryColumn {
    font-family: var(--fontFamilyHelmet);
     font-size: var(--fontSizeSubText);
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    border-bottom: 2px solid #000000;
    margin: 0;
    padding: 0;
    padding-bottom: 4px;
}